import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import Typography from '../../atoms/Typography'


const StyledSubDescription = styled(Typography)`
  margin-top: 2.5rem;
  width: 50%;

  ${media.down('mobile')(css`
    margin-top: 3.125rem;
    width: 100%;
  `)}
`

const SubpageSubDescription = ({children, ...props}) => {
  return (
    <StyledSubDescription {...props}>{children}</StyledSubDescription>
  )
}

SubpageSubDescription.propTypes = {
  children: PropTypes.node,
}

export default SubpageSubDescription
