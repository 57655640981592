import {mapValues} from 'lodash-es'
import pageData from '../../../content/settings/notFound.json'
import useLang from '../../hooks/useLang'
import AppShell from '../../components/containers/AppShell'
import SubpageHero from '../../components/molecules/SubpageHero'
import Navbar from '../../components/containers/Navbar'
import SubpageDescription from '../../components/molecules/SubpageHero/SubpageDescription'
import SubpageSubDescription from '../../components/molecules/SubpageHero/SubpageSubDescription'


const NotFoundPage = () => {
  const langToSlug = mapValues(pageData, 'slug')
  const {lang} = useLang('en', langToSlug)
  const {en} = pageData

  return (
    <AppShell title={en.menuText} lang={lang} langToSlug={langToSlug}>
      <Navbar title={en.returnMenuText} titleLink="/en" lang={lang} langToSlug={langToSlug} />
      <SubpageHero
          title={en.hero.title}
          fadedSideText={en.hero.sideTextNumber}
          sideText={en.hero.sideText}
      >
        <SubpageDescription>
          {en.hero.description}
        </SubpageDescription>
        <SubpageSubDescription>
          {en.hero.subDescription}
        </SubpageSubDescription>
      </SubpageHero>
    </AppShell>
  )
}

export default NotFoundPage
