import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import Typography from '../../atoms/Typography'


const StyledDescription = styled(Typography)`
  width: 50%;

  ${media.down('mobile')(css`
    width: 100%;
  `)}
`

const SubpageDescription = ({children, ...props}) => {
  return (
    <StyledDescription variant="p" type="h5" thin {...props}>{children}</StyledDescription>
  )
}

SubpageDescription.propTypes = {
  children: PropTypes.node,
}

export default SubpageDescription
